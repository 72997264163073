import { Controller } from "stimulus"
import scrollIt from "../lib/scroll.js"
import "particles.js/particles"
import ParitclesConfig from "./data/particles.json";

const particlesJS = window.particlesJS;

export default class extends Controller {
	static targets = [
		'confirmationLink',
		'secondaryForm',
		'nav',
		'signUp',
		'pageBody',
	]

	connect() {
		if(document.getElementById('particles-js')) {
			window.onload = function () {
				particlesJS('particles-js', ParitclesConfig);
			}
		}

		if (localStorage.getItem('showConfirm') === 'true') {
			if (this.hasConfirmationLinkTarget) this.confirmationLinkTarget.classList.add('is-active');
		}

    this.setupRecaptca();
    this.setUpResetPasswordCaptcha();
    this.setUpResendConfirmCaptcha();
    this.token = false;

    this.form = document.getElementById('new_user');
    if (this.form) {
      this.form.onsubmit = this.submit.bind(this)
    }
	}

  submit(event) {
    console.log(event)
    const isRecaptcha = document.getElementById('enroll-now-recaptcha');

    if (!this.token && isRecaptcha) {
      event.preventDefault();
      return false;
    }
  }

  setupRecaptca() {
    this.registerForm = document.getElementById('RegistrationForm');
    if (this.registerForm) {
      this.registerSubmitButton = document.getElementById('enroll-now-submit');
      window.showSubmit = this.showSubmit.bind(this);
  
      if (this.registerSubmitButton) {
        this.registerSubmitButton.style.display = "none";
      }
  
      this.recaptcha = this.registerForm.querySelectorAll(".g-recaptcha");
      this.recaptcha.forEach((captcha) => {
        captcha.setAttribute("data-callback", "showSubmit");
      });
    }
  }

  setUpResetPasswordCaptcha() {
    this.passwordForm = document.getElementById('ResetPasswordForm');
     if (this.passwordForm) {
      this.passwordSubmitButton = document.getElementById('password-reset-submit');
      window.passwordResetSubmit = this.passwordResetSubmit.bind(this);
  
      if (this.passwordSubmitButton) {
        this.passwordSubmitButton.style.display = "none";
      }
  
      this.recaptcha = this.passwordForm.querySelectorAll(".g-recaptcha");
      this.recaptcha.forEach((captcha) => {
        captcha.setAttribute("data-callback", "passwordResetSubmit");
      });
    }
  }

  setUpResendConfirmCaptcha() {
    this.confirmForm = document.getElementById('ResendConfirmationForm');
     if (this.confirmForm) {
      this.confirmSubmitButton = document.getElementById('resend-confirmation-submit');
      window.confirmEmailSubmit = this.confirmEmailSubmit.bind(this);
  
      if (this.confirmSubmitButton) {
        this.confirmSubmitButton.style.display = "none";
      }
  
      this.recaptcha = this.confirmForm.querySelectorAll(".g-recaptcha");
      this.recaptcha.forEach((captcha) => {
        captcha.setAttribute("data-callback", "confirmEmailSubmit");
      });
    }
  }
  
  passwordResetSubmit() {
    if (this.passwordSubmitButton) {
      this.passwordSubmitButton.style.display = "inline-block";
      this.passwordSubmitButton.disabled = false;
    } else {
      console.error("Password reset submit button not found.");
    }
  }

  confirmEmailSubmit() {
    if (this.confirmSubmitButton) {
      this.confirmSubmitButton.style.display = "inline-block";
      this.confirmSubmitButton.disabled = false;
    } else {
      console.error("Confirm reset submit button not found.");
    }
  }
    
	isFocused() {
		setTimeout(() => {
			if (window.location.pathname === '/login') return;
			this.secondaryFormTarget.classList.add('is-active');
		}, 300);
	}

	scroll() {
		scrollIt(
			this.navTarget,
			600,
			'easeOutQuad'
		);

		setTimeout(() => this.signUpTarget.focus(), 600);

		setTimeout(() => {
			this.secondaryFormTarget.classList.add('is-active');
		}, 800);
	}

	showConfirm() {
		localStorage.setItem('showConfirm', true);
	}

  showSubmit(token) {
    if (token) {
      this.token = token;
      if (this.registerSubmitButton) {
        this.registerSubmitButton.style.display = 'inline-block';
        this.registerSubmitButton.disabled = false;
        this.tmpDisableSubmitForSpam();
      }
      const recaptchaElement = document.getElementById('enroll-now-recaptcha');
      if (recaptchaElement) recaptchaElement.style.display = 'none';

      if (window.dataLayer) {
        window.dataLayer.push(
          {'gtm.element': {
            dataset: {
              category: 'Enroll Sign Up',
              action: 'Passed recaptcha',
              label: 'recaptcha',
              value: 'I\'m Human'}
          }, 'event' : 'trackEvent'}
        )
      }
    }
	}

  tmpDisableSubmitForSpam() {
    let submitBtn = document.getElementById('enroll-now-submit');
    submitBtn.setAttribute('disabled', true);
    setTimeout(() => {
      submitBtn.removeAttribute('disabled');
    }, 1000);
  }

	cookiesConsentClick() {
		this.pageBodyTarget.classList.remove('cookies-not-allowed');
	}
}
